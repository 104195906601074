<template>
  <div class="contaner">
    <Alert :message="marketsError" type="alert-danger" v-if="marketsError" />
    <Alert
      :message="suspendSuccess"
      type="alert-success"
      v-if="suspendSuccess"
    />
    <Alert :message="suspendError" type="alert-danger" v-if="suspendError" />
    <div class="row">
      <div class="col-12">
        <button
          class="btn btn-success  my-3"
          style="float:right"
          @click="clear"
          data-toggle="modal"
          data-target="#addEntity"
        >
          <i class="fas fa-pen-alt"></i> Add Entity
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card border-left-danger">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <p
                  style="float:left; color:red; font-weight:bold;font-size:1.3em"
                  class="my-2 text-center"
                >
                  Entities
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table
                        class="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Entity Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(entity, key) in entities" :key="key">
                            <td>{{ entity.name }}</td>
                            <td class="actions">
                              <button
                                href="#"
                                class="btn btn-info mr-1"
                                @click="entityDetail(entity.id, entity.key)"
                                data-toggle="modal"
                                data-target="#entityDetails"
                              >
                                <i class="fas fa-eye"></i> view
                              </button>
                              <button
                                @click="
                                  getDetails(
                                    entity.id,
                                    entity.name,
                                    entity.market_name,
                                    entity.type,
                                    entity.email,
                                    entity.phone,
                                    key
                                  )
                                "
                                class="btn btn-success mr-1"
                                data-toggle="modal"
                                data-target="#updateMarket"
                              >
                                <i class="far fa-edit"> </i> edit
                              </button>
                              <button
                                v-if="!entity.suspended"
                                @click="suspendEntity(entity.id, key)"
                                class="btn btn-danger mr-1"
                                data-toggle="modal"
                                data-target="#deleteMarket"
                              >
                                <i class="fas fa-lock"></i>suspend
                              </button>
                              <button
                                v-if="entity.suspended"
                                @click="unsuspendEntity(entity.id, key)"
                                class="btn btn-primary mr-1"
                                data-toggle="modal"
                                data-target="#deleteMarket"
                              >
                                <i class="fas fa-lock-open"></i> unsuspend
                              </button>
                              <button
                                v-if="!entity.data_provider"
                                class="btn btn-secondary mr-1"
                                data-toggle="modal"
                                data-target="#allowDataProvider"
                                @click="
                                  getDetails(
                                    entity.id,
                                    entity.name,
                                    entity.market_name,
                                    entity.type,
                                    entity.email,
                                    entity.phone,
                                    key
                                  )
                                "
                              >
                                <i class="fas fa-key"></i>assign data provider
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Entity Name</th>
                            <th>Actions</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--add entity modal content -->
    <div
      class="modal fade"
      id="addEntity"
      tabindex="-1"
      aria-labelledby="add market"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              style="float:left; color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel"
            >
              Add Entity
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center" v-if="success">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-success" role="alert">
                  <strong>{{ success }}</strong>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" v-if="error">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-danger" role="alert">
                  <strong>{{ error }}</strong>
                </div>
              </div>
            </div>
            <form class="" @submit.prevent="addEntity">
              <div class="form-row">
                <div class="form-group mb-2 col-md-6">
                  <label for="organization" class="form-label"
                    >Organization Name:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="organization"
                    required
                    placeholder="Name of Agency or Oganization"
                    v-model.trim="name"
                  />
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="market" class="form-label">Select Market:</label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model.trim="market"
                    >k
                    <option
                      v-for="(market, key) in markets"
                      :key="key"
                      :value="market.id"
                      >{{ market.name }}</option
                    >
                  </select>
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="email" class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    required
                    placeholder="me@example.com"
                    v-model.trim="email"
                  />
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="exampleFormControlSelect1"
                    >Select Market Type:</label
                  >
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model.trim="type"
                  >
                    <option>Organisation</option>
                    <option>Outlet</option>
                    <option>Agency</option>
                  </select>
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="staticEmail2" class="form-label"
                    >Phone Number:</label
                  >
                  <vue-tel-input v-model="phone"></vue-tel-input>
                </div>
                <div class="col-md-6 py-3">
                  <button type="submit" class="btn btn-danger  my-3 addbutton">
                    <span
                      v-if="loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Add market
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end of modal content -->
    <!--  update entity modal content -->
    <div
      class="modal fade"
      id="updateMarket"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              style="color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel"
            >
              Update Entity
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center" v-if="success">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-success" role="alert">
                  <strong>{{ success }}</strong>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" v-if="error">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-danger" role="alert">
                  <strong>{{ error }}</strong>
                </div>
              </div>
            </div>
            <form class="" @submit.prevent="updateEntity">
              <div class="form-row">
                <div class="form-group mb-2 col-md-6">
                  <label for="organization" class="form-label"
                    >Organization Name:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="organization"
                    required
                    placeholder="Name of Agency or Oganization"
                    v-model.trim="name"
                  />
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="market" class="form-label">Select Market:</label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model.trim="market"
                  >
                    <option
                      v-for="(market, key) in markets"
                      :key="key"
                      :value="market.id"
                      >{{ market.name }}</option
                    >
                  </select>
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="email" class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    required
                    placeholder="me@example.com"
                    v-model.trim="email"
                  />
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="exampleFormControlSelect1"
                    >Select Market Type:</label
                  >
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model.trim="type"
                  >
                    <option>Organisation</option>
                    <option>Outlet</option>
                    <option>Agency</option>
                  </select>
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="staticEmail2" class="form-label"
                    >Phone Number:</label
                  >
                  <vue-tel-input v-model="phone"></vue-tel-input>
                </div>
                <div class="col-md-6 py-3">
                  <button
                    type="submit"
                    class="btn btn-danger my-2 btn-lg addbutton"
                  >
                    <span
                      v-if="loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="allowDataProvider"
      tabindex="-1"
      aria-labelledby="Delete Market"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              style="float:left; color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel"
            >
              Assign Data Provider
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center" v-if="success">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-success" role="alert">
                  <strong>{{ success }}</strong>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" v-if="error">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-danger" role="alert">
                  <strong>{{ error }}</strong>
                </div>
              </div>
            </div>
            <button
              :disabled="success"
              style="float:right"
              class="btn btn-danger"
              @click="assignDataProvider"
            >
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end of allow data provider modal content -->
    <!-- start of  entity details modal  content -->
    <div
      class="modal fade"
      id="entityDetails"
      tabindex="-1"
      aria-labelledby="Delete Market"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-dark"
              style="color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel "
            >
              Entity Detail
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card border-left-danger m-4">
                <div class="card-body">
                  <p>
                    <span>Name</span>:<strong>{{ entity.name }}</strong>
                  </p>
                  <p>
                    <span>Market Name</span>:<strong>{{
                      entity.market_name
                    }}</strong>
                  </p>
                  <p>
                    <span>Data Provider</span>:<strong>{{
                      entity.data_provider ? 'Yes' : 'No'
                    }}</strong>
                  </p>
                  <p>
                    <span>Type</span>:<strong>{{ entity.type }}</strong>
                  </p>
                  <p>
                    <span>Provisional AFCFTA Number</span>:<strong>{{
                      entity.provisional_afcfta_number
                    }}</strong>
                  </p>
                  <p>
                    <span>Final AFCFTA Number</span>:<strong>{{
                      entity.final_afcfta_number
                    }}</strong>
                  </p>
                  <p>
                    <span>Email</span>:<strong>{{ entity.email }}</strong>
                  </p>
                  <p>
                    <span>Phone Number</span>:<strong>{{
                      entity.phone
                    }}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of entity details modal content -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { VueTelInput } from 'vue-tel-input';
import Alert from '@/components/Alert.vue';

export default {
  name: 'Markets',
  components: {
    VueTelInput: VueTelInput,
    Alert: Alert,
  },
  data() {
    return {
      name: null,
      market: null,
      type: null,
      phone: null,
      email: null,
      addError: null,
      marketsError: null,
      key: null,
      id: null,
      entity: {},

      success: null,
      error: null,
      loading: false,
      suspendSuccess: null,
      suspendError: null,
    };
  },
  methods: {
    clear() {
      this.error = null;
      this.success = null;
      this.name = null;
      this.market = null;
      this.type = null;
      this.phone = null;
      this.email = null;
    },
    assignDataProvider() {
      this.$store
        .dispatch('assignDataProvider', { key: this.key, id: this.id })
        .then((response) => {
          this.success = 'Assigned';
        })
        .catch((err) => {
          if (err?.response?.data === undefined) {
            this.error === 'Network Error.';
          }
        });
    },
    entityDetail(entitiyid, key) {
      this.entity = this.entities.find((entity) => entity.id === entitiyid);
    },
    getDetails(id, name, market, type, email, phone, key) {
      // reset error and success messages to to null
      this.error = null;
      this.success = null;

      this.id = id;
      this.name = name;
      this.market = market;
      this.type = type;
      this.email = email;
      this.phone = phone;
      this.key = key;
    },
    updateEntity() {
      this.error = null;
      this.success = null;

      const data = {
        id: this.id,
        name: this.name,
        market: this.market,
        email: this.email,
        phone: this.phone,
        type: this.type,
        key: this.key,
      };
      this.$store
        .dispatch('updateEntity', data)
        .then((response) => {
          this.success = 'Update success.';
        })
        .catch((err) => {
          console.log('err', err);
          if (err?.response?.data === undefined) {
            this.error = 'Network Error.';
          }
        });
    },
    suspendEntity(id, key) {
      this.error = null;
      this.success = null;
      const data = {
        id: id,
        key: key,
      };
      this.$store
        .dispatch('suspendEntity', data)
        .then((response) => {
          console.log(response);
          this.suspendSuccess = 'Entity Suspended.';
        })
        .catch((err) => {
          console.log('error', err);
          if (err?.response?.data === undefined) {
            this.suspendError = 'Network Error.';
          }
        });
    },
    unsuspendEntity(id, key) {
      this.suspendSuccess = null;
      this.suspendError = null;
      const data = {
        id: id,
        key: key,
      };
      this.$store
        .dispatch('unsuspendEntity', data)
        .then((response) => {
          console.log(response);
          this.suspendSuccess = 'Entity suspended.';
        })
        .catch((err) => {
          if (err?.response?.data === undefined) {
            this.suspendError = 'Network Error.';
          }
        });
    },
    addEntity() {
      this.error = null;
      this.success = null;
      this.loading = true;

      const data = {
        name: this.name,
        market: this.market,
        type: this.type,
        email: this.email,
        phone: this.phone,
      };
      this.$store
        .dispatch('addEntity', data)
        .then((response) => {
          this.loading = false;
          this.success = 'Entity created successfully.';
          this.name = null;
          this.market = null;
          this.type = null;
          this.email = null;
          this.phone = null;
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            this.loading = false;
            this.error = 'Entity already exist.';
          }
          if (err?.response?.data === undefined) {
            this.loading = false;
            this.error = 'Network error.';
          }
        });
    },
    getMarkets() {
      this.$store.dispatch('getAllMarkets').catch((err) => {
        if (err?.response?.data === undefined) {
          this.error = 'Network Error.';
        }
      });
    },
    getEntities() {
      this.$store.dispatch('getEntities').catch((err) => {
        if (err?.response?.data === undefined) {
          this.error = 'Network Error.';
        }
      });
    },
  },
  computed: {
    ...mapGetters(['markets', 'entities', 'unApprovedEntities']),
    details() {
      return this.entityDetail();
    },
  },
  created() {
    this.getMarkets();
    this.getEntities();
  },
};
</script>
<style>
.addbutton {
  width: 100%;
  font-size: 1em;
}
.btn,
table tr td {
  font-size: 0.9em;
}
</style>
